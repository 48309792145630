<template>
  <div class="component-wrap height-182">
    <a-button type="primary" class="back" @click="back">
      {{ $t("go_back") }}
      <!-- 返回 -->
    </a-button>
    <div class="component-tit">{{ planData.planName }}</div>
    <div class="component-info">
      <div class="seal">
        <div class="img">
          <img
            src="../../../../assets/image/completed-seal.png"
            alt=""
            v-if="planData.status == 1"
          />
          <img
            src="../../../../assets/image/no-complete-seal.png"
            alt=""
            v-else
          />
        </div>
      </div>
      <p class="time">
        <span>{{ $t("idp.supervisor") }}：{{ planData.collaboratorName }}</span>
      </p>
      <p class="text">
        <span
          >{{ $t("idp.rate_of_learning") }}：<i class="num">{{
            planData.progress
          }}</i
          >/{{ planData.details.length }} ({{
            percentage(planData.progress, planData.details.length)
          }})</span
        >
      </p>
    </div>
  </div>
  <div class="component-wrap height-auto">
    <div class="component-tit">
      <span>{{ $t("idp.learning_content") }}</span>
    </div>
    <div class="details">
      <div class="item" v-for="(item, index) in planData.details" :key="index">
        <div class="index">
          <span>{{ index + 1 }}</span>
        </div>
        <div class="name" v-if="item.resourceType == 5">
          {{ item.homework.homeworkName }}
        </div>
        <div class="name" v-else>{{ item.detailName }}</div>
        <div class="flex">
          <div class="type">{{ getDetailType(item.resourceType) }}</div>
          <div class="status green" v-if="item.status == 1">
            {{ $t("idp.completed") }}
          </div>
          <div class="status red" v-else>{{ $t("idp.incomplete") }}</div>
        </div>
        <div class="btn" @click="toDetail(item, index)">
          {{
            item.resourceType == 34 && item.signUpStatus != 1
              ? $t("Pub_Tab_GoSign")
              : $t("idp.view_details")
          }}
        </div>
      </div>
    </div>
    <homeworkDrawer ref="homeworkRef" @save="addHomework" />
  </div>
</template>

<script>
import { reactive, toRefs, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

import { idpDetail, idpHomeworkSubmit } from "@/api/idp";

import { getDetailType } from "@/utils/business";

import homeworkDrawer from "./drawer/homework.vue";

export default {
  components: {
    homeworkDrawer,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      planId: Number(route.query.planId || 0),
      planData: {
        details: [],
      },
    });

    const back = () => {
      router.go(-1);
    };

    idpDetail(state.planId).then((res) => {
      state.planData = res.data;
    });

    const addHomework = ({ data, index }) => {
      let params = {
        planId: data.planId,
        detailId: data.detailId,
        homework: data,
      };
      idpHomeworkSubmit(params).then((res) => {
        if (res.ret == 0) {
          state.planData.details[index].homework = data;
        }
      });
    };

    const homeworkRef = ref(null);
    const toDetail = (item, index) => {
      let useNewWindow = store.getters.companyInfo.useNewWindow == 1;
      switch (item.resourceType) {
        case 2:
          if (useNewWindow) {
            window.open(
              `/course/detail?ddtab=true&id=${item.resourceId}&planId=${state.planId}`
            );
          } else {
            router.push({
              path: "/course/detail",
              query: {
                id: item.resourceId,
                planId: state.planId,
              },
            });
          }
          break;
        case 3:
          if (useNewWindow) {
            window.open(
              `/exam/detail?ddtab=true&id=${item.resourceId}&did=${item.taskDetailId}&isIdp=${1}`
            );
          } else {
            router.push({
              path: "/exam/detail",
              query: {
                id: item.resourceId,
                did: item.taskDetailId,
                isIdp: 1,
              },
            });
          }
          break;
        case 5:
          homeworkRef.value.open(item.homework, index);
          break;
        case 33:
          if (useNewWindow) {
            window.open(
              `/questionnaire/detail?ddtab=true&source=idp&id=${
                item.resourceId
              }&did=${item.taskDetailId}&resourceId=${
                item.questionnaireId
              }&progress=${item.status == 1 ? 100 : 0}`
            );
          } else {
            router.push({
              path: "/questionnaire/detail",
              query: {
                source: "idp",
                id: item.resourceId,
                did: item.taskDetailId,
                resourceId: item.questionnaireId,
                progress: item.status == 1 ? 100 : 0,
              },
            });
          }
          break;
        case 34:
          if (item.signUpStatus == 1) {
            if (useNewWindow) {
              window.open(`/project/detail?ddtab=true&id=${item.resourceId}`);
            } else {
              router.push({
                path: "/project/detail",
                query: {
                  id: item.resourceId,
                },
              });
            }
          } else {
            if (useNewWindow) {
              window.open(`/signup/detail?ddtab=true&id=${item.resourceId}`);
            } else {
              router.push({
                path: "/signup/detail",
                query: {
                  id: item.resourceId,
                },
              });
            }
          }
          break;
      }
    };

    const percentage = (n1, n2) => {
      if (n2 === 0 || isNaN(n1) || isNaN(n2)) {
        return "0%";
      }
      return Math.round((n1 / n2) * 100) + "%";
    };

    return {
      getDetailType,
      ...toRefs(state),
      back,
      addHomework,
      homeworkRef,
      toDetail,
      percentage,
    };
  },
};
</script>

<style lang="less" scoped>
@import "../index.less";
.component-wrap {
  &.height-182 {
    min-height: auto;
    height: 182px;
    position: relative;
    .component-info {
      border-top: 1px solid #f4f4f4;
      padding: 16px 0;
      margin-top: 4px;
      position: relative;
      .seal {
        position: absolute;
        top: 16px;
        right: 0;
        .img {
          .mixinImgWrap(56px; 56px);
        }
      }
      p {
        font-size: 16px;
        line-height: 26px;
        width: 388px;
        &.time {
          margin-bottom: 8px;
        }
        &.text {
          margin-bottom: 0;
          .mixinFlex(space-between; center);
          .num {
            color: #377dff;
          }
        }
      }
    }
    .back {
      position: absolute;
      right: 24px;
      top: 29px;
    }
  }
  &.height-auto {
    min-height: unset;
    margin-top: 20px;
    max-height: 2000px;
    overflow-y: auto;
    padding-right: 16px;
    .details {
      .mixinFlex();
      flex-wrap: wrap;
      .item {
        padding: 24px 16px 20px;
        width: 289px;
        height: 262px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(148, 148, 148, 0.1);
        margin: 0 20px 20px 0;
        &:nth-child(3n) {
          margin-right: 0;
        }
        .index {
          color: #fff;
          font-size: 16px;
          font-weight: bold;
          .mixinFlex(center; center);
          width: 32px;
          height: 32px;
          background-color: @color-theme;
          border-radius: 50%;
          margin: 8px auto 24px;
          position: relative;
          &::before {
            content: "";
            width: 48px;
            height: 48px;
            position: absolute;
            top: -8px;
            left: -8px;
            background-color: @color-theme;
            opacity: 0.3;
            border-radius: 50%;
          }
          span {
            z-index: 1;
          }
        }
        .name {
          font-size: 16px;
          .mixinEllipsis(52px, 2);
          margin-bottom: 20px;
        }
        .flex {
          margin-bottom: 20px;
          .mixinFlex(space-between; center);
          color: #666;
          font-size: 16px;
          line-height: 26px;
          .status {
            &.green {
              color: #22cf33;
            }
            &.red {
              color: #e8673e;
            }
          }
        }
        .btn {
          height: 36px;
          border-radius: 4px;
          border: 1px solid @color-theme;
          color: @color-theme;
          .mixinFlex(center; center);
          cursor: pointer;
        }
      }
    }
  }
}
</style>
